//GLOBAL
import React, { createRef } from 'react'
import { connect } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';

//UI
import { CCard, CCardBody, CCol, CFormCheck, CRow, CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react-pro';

//UTILS
import withRouter from '../../utils/withNavigation';
import store from "../../redux/store";

//COMPONENTS
import { mapStateToProps } from './AbstractComponent';
import AbstractDetail from './AbstractDetail';
import UiCard from '../ui/UiCard';
import UiInput from '../ui/UiInput';
import UiDatePicker from '../ui/UiDatePicker';
import UiPictureUpload from '../ui/UiPictureUpload';
import UiRelationBox from '../ui/UiRelationBox';
import UiRadioButton from '../ui/UiRadioButton';
import UiCheckbox from '../ui/UiCheckbox';
import UiSelectClassic from '../ui/UiSelectClassic';
import UiAutocomplete from '../ui/UiAutocomplete';
import UiButton from '../ui/UiButton';

//SERVICE
import UserAddressesService from '../../services/userAddressesService';
import ConstantsService from '../../services/constantsService';
import ObjectsUtils from '../../utils/objectsUtils';
import UsersService from '../../services/usersService';
import MapService from '../../services/mapService';
import RolesService from '../../services/rolesService';
import UiCheckboxGroup from '../ui/UiCheckboxGroup';

class User extends AbstractDetail {

  constructor(props) {
    super(props);

    this.state.userAddresses = [];
    this.userAddressesService = UserAddressesService.getInstance(store);
    this.state.taxIdError = false;
    this.state.psw = '';
    this.state.confirm_psw = '';
    this.state.isAccordionOpen = false;
    this.state.rolesList = [];
    this.state.currentRole = null;

    this.accordionRef = createRef();
    this.counter = 0;

    //services
    this.userService = UsersService.getInstance(store);
    this.mapService = MapService.getInstance(store);
    this.rolesService = RolesService.getInstance(store);
  }

  componentDidMount() {
    this.rolesService.getList({}, this.globalService.currentLanguage, this.globalService.currentUser, (data) => this.setState({
      rolesList: data.data
    }));
  }

  componentDidUpdate() {
    if (this.state?.model?.id && this.counter === 0) {
      this.counter++;
      this.userService.getAddresses(this.state?.model?.id, this.globalService.currentLanguage, this.globalService.currentUser, this.setData.bind(this));

    }
  }

  updateModel() {
    if (!this.computedItemId && !this.itemId) {
      return;
    }
    super.updateModel();
  }

  setData(evt) {
    this.setState({
      userAddresses: evt.data,
    })
  }

  isCreationValid(evt) {
    if (evt.type && evt?.address && evt?.address !== '' && evt?.zip_code && ObjectsUtils.isValidZipCode(evt?.zip_code) && evt?.city_id && evt?.start_date) {
      return true;
    } else {
      return false;
    }
  }

  handleOnEditAddress(evt) {
    this.userAddressesService.updateItem(evt, this.globalService?.currentLanguage, this.globalService?.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('modalAddAddress');
  }

  handleOnDeleteAddress(evt) {
    this.userAddressesService.deleteItem(this.itemId, evt.id, this.globalService?.currentLanguage, this.globalService?.currentUser, this.okCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('modalDeleteAddress');
  }

  handleOnAddAddress(evt) {
    const newObj = {
      user_id: this.itemId,
      coordinate: evt.coordinate ? evt.coordinate : undefined,
      city_id: evt.city_id ? Number(evt.city_id) : undefined,
      end_date: evt.end_date ? evt?.end_date?.includes('T') ? evt?.end_date?.split('T')[0] : evt?.end_date : undefined,
      start_date: evt.start_date ? evt?.start_date?.includes('T') ? evt?.start_date?.split('T')[0] : evt?.start_date : undefined,
      address: evt.address ? evt.address : undefined,
      type: evt.type ? evt.type : undefined,
      zip_code: evt.zip_code ? evt.zip_code : undefined,
      label: evt.label ? evt.label : undefined,
    }
    this.userAddressesService.addItem(newObj, this.globalService.currentLanguage, this.globalService.currentUser, this.refreshOkCallback.bind(this), this.koCallback.bind(this));
    this.toggleModal('modalAddAddress');
  }

  handleOnFiscalCodeChange(evt) {
    this.handleOnChange(evt);
    if (ObjectsUtils.isValidTaxIdCode(evt.target.value)) {
      this.setState({
        taxIdError: false
      });
    } else {
      this.setState({
        taxIdError: true
      });
    }
  }

  async refreshOkCallback(data) { //if address creation is ok update address coordinates with google maps api
    let address = '';
    if (data?.data) {
      address = data.data.address + ',' + data.data.city.city;
      let res = await this.mapService.findAddress(address);
      if (res !== null && res !== false) {
        const obj = {
          ...data.data,
          coordinates: res
        }
        delete obj.user;
        delete obj.city;
        obj.city_id = data.data.city.id;
        obj.user_id = data.data.user.id;
        await this.userAddressesService.updateItem(data.data.user.id, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallback.bind(this), this.koCallbackPsw.bind(this));
      } else {
        this.userAddressesService.deleteItem(this.itemId, data.data.id, this.globalService?.currentLanguage, this.globalService?.currentUser, () => {
          this.userService.getAddresses(this.state?.model?.id, this.globalService.currentLanguage, this.globalService.currentUser, this.setData.bind(this));
        }, () => { });
        return toast.error(`${i18n.t('Common.error')}`);
      }
    }

  }

  okCallback() {
    this.getData();
    return toast.success(i18n.t('Common.success'));
  }

  checkEqualPsw(psw, confirmPsw) {
    if (psw === confirmPsw && psw.length > 0 && confirmPsw.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  handlePswChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value
    })
  }

  handleAccordionToggle = () => {
    this.setState(prevState => ({ isAccordionOpen: !prevState.isAccordionOpen }), () => {
      if (this.state.isAccordionOpen) {
        setTimeout(() => {
          if (this.accordionRef.current) {
            this.accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }, 300); // Adjust timeout duration if necessary
      }
    });
  };

  submitPsw() {
    const obj = {
      password: this.state.psw
    }
    this.userService.changePsw(this.state.model.id_user, obj, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackPsw.bind(this), this.koCallbackPsw.bind(this));
  }

  okCallbackPsw() {
    this.setState({
      psw: '',
      confirm_psw: '',
      changing: false
    }, () => { this.props.onSaveButtonEnabled(this.state.changing); })
    return toast.success(i18n.t('Common.success'));
  }

  koCallbackPsw(response, firstError) {
    if (firstError) {
      return toast.error(`${i18n.t('Common.error')}: ${ObjectsUtils.buildError(firstError)}`, { autoClose: 5000 });
    }
    if (response.status > 399 || response.data.status > 399) {
      return toast.error(`${i18n.t('Common.error')}: ${ObjectsUtils.buildError(response)}`, { autoClose: 5000 });
    }
    return toast.success(i18n.t('Common.success'));
  }



  renderAddressRow(address) {
    return (
      <CRow>
        <CCol>{i18n.t('SelectValues.' + address.type)}</CCol>
        <CCol>{address.label ? address.label : '-'}</CCol>
        <CCol>{address.address}</CCol>
        <CCol>{address.city}</CCol>
        <CCol>{moment(address.start_date).format('DD/MM/YYYY')}</CCol>
        <CCol>{address.end_date ? moment(address.end_date).format('DD/MM/YYYY') : '-'}</CCol>
      </CRow>
    )
  }

  renderAddressHeader() {
    return (
      <CRow className='fw-bold'>
        <CCol>{i18n.t('Table.type')}</CCol>
        <CCol>{i18n.t('Table.label')}</CCol>
        <CCol>{i18n.t('Table.address')}</CCol>
        <CCol>{i18n.t('Table.city_id')}</CCol>
        <CCol>{i18n.t('Table.start_date')}</CCol>
        <CCol>{i18n.t('Table.end_date')}</CCol>
        <CCol sm="1" md="1" lg="1" xl="1"></CCol>
      </CRow>
    )
  }

  handleDisable() {
    if (!this.state.model.disable_date || this.state.model.disable_date === null) {
      let item = cloneDeep(this.state.model);
      item.disable_date = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      // item.pic
      this.sectionService.updateItem(item, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    } else {
      let item = cloneDeep(this.state.model);
      item.disable_date = null;
      this.sectionService.updateItem(item, this.globalService.currentLanguage, this.globalService.currentUser, this.okCallbackWithRefresh.bind(this), this.koCallback.bind(this));
    }
  }

  renderChildren(t) {
    this.roles = this.state.model?.role_ids ? this.state.model?.role_ids.map(role => role.toString()) : [];
    return (
      <>
        {this.renderToast()}
        <CCard>
          <CCardBody>
            {/* <UiCard className="mt-4 colored-header" icon="cis-group" title={t('Table.pic_url')} collapsible={false}> */}
            <CRow>
              <CCol sm="12" md="12" lg="12" xl="12">
                <UiPictureUpload name='pic_url' label={'Table.pic_url'}
                  disabled={!this.state.editable}
                  value={this.state.model.pic_url}
                  onChange={this.onNewImageChange.bind(this, this.state.model, 'pic_url')}
                  onDelete={this.onImageDelete.bind(this, this.itemId)}
                  deletable fullScreenEnabled editable
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12" md="6" lg="6" xl="6">
                <UiCheckbox name="pic_on_site" label="Table.show_on_site"
                  disabled={!this.state.editable}
                  required={this.state.mandatoryFields.indexOf('pic_on_site') >= 0}
                  value={this.state.model.pic_on_site}
                  onFocus={this.handleOnFocus.bind(this)}
                  onChange={this.handleOnChange.bind(this)}
                  onBlur={this.handleOnBlur.bind(this)}
                />
              </CCol>
              <CCol sm="12" md="6" lg="6" xl="6" className="rightCol mt-3" style={{ width: '200px' }}>
                {
                  (this.globalService.currentUser.userGroup.includes('ROLE_ADMIN') || this.globalService.currentUser.userGroup.includes('ROLE_ADMIN')) &&
                  <UiButton
                    label={!this.state.model.disable_date || this.state.model.disable_date === null ? t('Common.disable') : t('Common.reactivate')}
                    onClick={this.handleDisable.bind(this)}
                  />
                }
              </CCol>
            </CRow>
            {/* </UiCard> */}
            <div style={{ width: '50%', margin: '0 auto' }}>
              <CAccordion className='mt-4' onClick={this.handleAccordionToggle.bind(this)} ref={this.accordionRef} >
                <CAccordionItem itemKey={1}>
                  <CAccordionHeader className='user-accordion-psw-header'>{t('Common.change_psw')}</CAccordionHeader>
                  <CAccordionBody >
                    <CRow>
                      <CCol sm="12" md="6" lg="6" xl="6">
                        <UiInput type="password" name="psw" label="Common.new_password"
                          disabled={!this.state.editable}
                          value={this.state.psw}
                          onFocus={this.handleOnFocus.bind(this)}
                          onChange={this.handlePswChange.bind(this)}
                          onBlur={this.handleOnBlur.bind(this)}
                          invalid={false}
                        />
                      </CCol>
                      <CCol sm="12" md="6" lg="6" xl="6" className='rightCol'>
                        <UiInput type="password" name="confirm_psw" label="Common.repeat_new_password"
                          disabled={!this.state.editable}
                          value={this.state.confirm_psw}
                          onFocus={this.handleOnFocus.bind(this)}
                          onChange={this.handlePswChange.bind(this)}
                          onBlur={this.handleOnBlur.bind(this)}
                          invalid={false}
                        />
                      </CCol>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0px !important', marginTop: '20px' }}>
                        <UiButton label={t('Common.enter')} disabled={!this.checkEqualPsw(this.state.psw, this.state.confirm_psw)} onClick={this.submitPsw.bind(this)} />
                      </div>
                    </CRow>
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            </div>
          </CCardBody>
        </CCard>
        <UiCard className="mt-4 colored-header" icon="cis-group" title={t('Table.info')} collapsible={false}>
          <UiCheckboxGroup
            name="role_ids"
            values={this.state?.rolesList}
            label={!this.allSelected ? "Common.select_all" : "Common.uncheck_all"}
            inline={false}
            value={this.roles}
            onChange={this.handleOnChange.bind(this)} style={{ marginBottom: '20px' }}
            fieldDisplayed={"name"} fieldReturned={"id"}
            disabled={false}
            columns={2}
          />
          <CRow className='user-row-padding'>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="first_name" label="Table.name"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('first_name') >= 0}
                value={this.state.model.first_name}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
              <UiInput type="text" name="last_name" label="Table.last_name"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('last_name') >= 0}
                value={this.state.model.last_name}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow className='user-row-padding'>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="email" label="Table.email"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('email') >= 0}
                value={this.state.model.email}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
              <UiDatePicker type="text" name="birth_date" label="Table.birth_date"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('birth_date') >= 0}
                value={this.state.model.birth_date}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
          <CRow className='user-row-padding'>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiAutocomplete name="birth_place_id" label="Table.birth_place" route="city" currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer}
                values={this.props.apiReducer?.cities?.data} reducer={"cities"}
                value={this.state.model?.birth_place_id} fieldDisplayed="city" fieldReturned="id"
                onSearchChange={this.handleOnSearchChange.bind(this)} onChange={this.handleOnChange.bind(this)}
              />
            </CCol>
            <CCol sm="12" md="6" lg="6" xl="6" className="rightCol">
              <UiSelectClassic name='nationality' label={'Table.nationality'} disabled={!this.state.editable} forceTranslation
                value={this.state.model?.nationality} required={this.state.mandatoryFields.indexOf('nationality') >= 0} nullable
                values={ConstantsService.nationalities} fieldDisplayed='name' fieldReturned='value'
                onFocus={this.handleOnFocus.bind(this)} onChange={this.handleOnChange.bind(this)} onBlur={this.handleOnBlur.bind(this)}
                onValueChanged={this.handleOnForcedChange.bind(this)} />
            </CCol>
          </CRow>
          <CRow className='user-row-padding'>
            <CCol sm="12" md="12" lg="12" xl="12">
              <UiRadioButton name='gender' label="Table.gender" values={ConstantsService.gender_values}
                required={this.state.mandatoryFields.indexOf('gender') >= 0} inline gap={'50px'}
                value={this.state.model.gender} fieldDisplayed='label' fieldReturned='value'
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)} />
            </CCol>
          </CRow>
        </UiCard>
        <UiRelationBox
          title='Table.addresses' icon='cis-location-pin' model={this.state.userAddresses} disabled={!this.state.editable} cardIsOpen
          currentUser={this.globalService.currentUser} apiReducer={this.props.apiReducer} route='user_address' apiReducerName={'user_addresses'}
          renderRow={this.renderAddressRow.bind(this)} renderHeader={this.renderAddressHeader.bind(this)}
          isCreationValid={this.isCreationValid.bind(this)}
          creationModel={{
            user_id: this.itemId,
            start_date: new Date().toISOString().slice(0, 10),
          }}
          toggleModal={this.toggleModal.bind(this, 'modalAddAddress')}
          isModalOpen={this.state.modalAddAddress}
          toggleDeleteModal={this.toggleModal.bind(this, 'modalDeleteAddress')}
          isDeleteModalOpen={this.state.modalDeleteAddress}
          insertable deletable editable onSearchChange={this.handleOnSearchChange.bind(this)}
          onEdit={this.handleOnEditAddress.bind(this)}
          onAdd={this.handleOnAddAddress.bind(this)}
          onDelete={this.handleOnDeleteAddress.bind(this)}
        />

        <UiCard className="mt-4 colored-header" icon="cis-money" title={t('Table.fiscal_data')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiInput type="text" name="fiscal_code" label="Table.fiscal_code"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('fiscal_code') >= 0}
                value={this.state.model.fiscal_code}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnFiscalCodeChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
                invalid={this.state?.taxIdError}
                className="tax-id-input"
              />
            </CCol>
          </CRow>
        </UiCard>

        <UiCard className="mt-4 colored-header" icon="cis-file-doc" title={t('Table.contractual_data')} collapsible={false}>
          <CRow>
            <CCol sm="12" md="6" lg="6" xl="6">
              <UiDatePicker type="text" name="hire_date" label="Table.hire_date"
                disabled={!this.state.editable}
                required={this.state.mandatoryFields.indexOf('hire_date') >= 0}
                value={this.state.model.hire_date}
                onFocus={this.handleOnFocus.bind(this)}
                onChange={this.handleOnChange.bind(this)}
                onBlur={this.handleOnBlur.bind(this)}
              />
            </CCol>
          </CRow>
        </UiCard>

      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(User));
